import * as React from "react"
import PropTypes from "prop-types"
import { Container } from 'react-bootstrap';
// import { GatsbyImage } from "gatsby-plugin-image"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faHorizontalRule } from '@fortawesome/pro-regular-svg-icons'
import SpeakerCard from "./elements/speakerCard"


const Speakers = ({ speakers }) => {
  return(
    <Container className="block-speakers align-items-center justify-content-between flex-column flex-md-row col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
      {speakers.map((speaker, i) => {
        return (
          <SpeakerCard 
            name="Speakers"
            speaker={speaker}
            id={`blog_${speaker.id}`}
            key={i}
          />
        )
      })}
      
    </Container>
  )
}

Speakers.propTypes = {
  speakers: PropTypes.array
}

export default Speakers
