import * as React from "react"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHorizontalRule } from "@fortawesome/pro-regular-svg-icons"

const SpeakerCard = ({ name, speaker, id }) => {
  return (
    <Card key={id} id={id} className="speaker_card">
      <Card.Img
        variant="top"
        src={
          speaker.profile_picture?.localFile?.publicURL
            ? speaker.profile_picture?.localFile.publicURL
            : null
        }
        alt={
          speaker.profile_picture?.alternativeText
            ? speaker.profile_picture?.alternativeText
            : null
        }
      />
      <h5 className="speaker_position opacity-40">{speaker.position}</h5>
      <Card.Body>
        <h4 className="card-title">{speaker.full_name}</h4>
        <Card.Link href={`mailto:${speaker.email}`} className="btn btn-link">
          Neem contact op
          <FontAwesomeIcon icon={faHorizontalRule} />
        </Card.Link>
      </Card.Body>
    </Card>
  )
}

SpeakerCard.propTypes = {
  name: PropTypes.string,
  speaker: PropTypes.object,
}

export default SpeakerCard
